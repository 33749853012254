import { createSignal, splitProps } from "solid-js";
import SearchIcon from "./assets/search-normal.svg";
import type { SearchToolProps } from "./_model";
export default function SearchTool(props: SearchToolProps) {
  const [local, others] = splitProps(props, ["onSearch"]);
  const [searchTerm, setSearchTerm] = createSignal("");

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    local.onSearch(searchTerm());
  };

  return (
    <form class="relative flex w-full h-full form-input !h-2rem">
      <input
        value={searchTerm()}
        onInput={(e) => handleSearch(e)}
        type="text"
        placeholder="Search for ..."
        class="w-full  text-16px <md:(text-1.5vh)"
      />
      <SearchIcon class="h-full" />
    </form>
  );
}
